// 회원 API
// Create
// Fetch (Read)
// Update
// Delete
import { get, patch, post, put } from '..';

// 로그인
const requestLogin = payload => post('/auth/login', payload);

// 로그아웃
const requestLogout = () => post('/auth/logout', {});

// 비밀번호 확인
const requestCheckPassword = payload => post('/member/pwd/confirm', payload);

// 로그인 된 내 정보 가져오기
const fetchMyInfo = payload => post('/member/me', payload);

// 아이디 찾기
const requestFindIdByPhone = payload => post('/member/id/phone', payload);

// 비밀번호 찾기 - 새 비밀번호 지정
const requestResetPassword = payload => post('/member/pwd/reset', payload);

// ---------------- 회원정보 관련 ----------------

/**
 * @author Ash
 * @date 2024.03.25
 * @param { uid?: string }
 * @returns { name, memberTypeCode, memberTypeName, memberStatusCode, memberStatusName, certificate, regular, writeNameStatus, lastModifiedDate }
 * @description 회원상태체크 (정회원여부, 고정필명등록여부. 회원정보업데이트일자체크) uid 없으면 토큰에서 추출
 */
const requestMemberStatus = payload => get('/api/v3/member/status', payload);

/**
 * @author Ash
 * @date 2024.03.31
 * @returns { dutyCode, dutyName, medicalItemCode, medicalItemName, treatmentCode, treatmentName, hospitalName }
 * @description 의사회원 병원 정보 조회
 */
const requestMemberHospitalInfo = () => get('/api/v3/member/hospital');

// nice 초기값
const fetchNiceInit = payload => post('/auth/niceid/init', payload);

// nice decode
const requestDecodeNiceData = (payload, headers) => post('/auth/niceid/decode', payload, headers);

// 아이디 중복 체크
const requestCheckUid = payload => post('/member/memberCountByUid', payload);

// 필명 중복체크
const requestCheckWriteName = payload => post('/member/writeNameCount', payload);

// 회원가입
const createMember = payload => post('/member/insertMember', payload);

/**
 * @author Ash
 * @date 2024.03.26
 * @description 의사 회원 생성, 회원가입 개편으로 인해 요구 데이터가 감소 및 api 대응, 추후 통합 회원가입으로 변할 수 있음
 */
const createDoctor = payload => post('/v3/member/signup', payload);

// 전공과목 메인 코드 리스트 : 프론트 static 데이터로 대체
const fetchMainCodeList = payload => post('/common/mainCodeList', payload);

// 전공과목 서브코드 : 프론트 static 데이터로 대체
const fetchSubCodeList = payload => post('/common/subCodeList', payload);

// 기타 코드리스트 : 프론트 static 데이터로 대체
const fetchCodeList = payload => post('/common/codeList', payload);

// 회원정보 수정을 위한 정보 조회
const fetchMemberInfoForModify = payload => post('/member/info/detail', payload);

// 회원정보 수정
const updateMemberInfo = payload => post('/member/info/update', payload);

// 회원정보 수정
const updateMemberInfoV3 = payload => put('/v3/member', payload);

// 비밀번호 변경 (회원정보 수정)
const updatePassword = payload => post('/member/pwd/update', payload);

// 회원 탈퇴 사유목록 조회
const fetchLeaveReason = payload => post('/member/outreason', payload);

// 회원 탈퇴
const requestLeaveMember = payload => post('/member/leave', payload);

// 고정필명 수정
const updateWriteName = payload => post('/member/updateWriteName', payload);

// 수련병원
const fetchHospSearch = payload => post('/search/hospSearch', payload);

// 필명 중복체크
const requestCheckAliasWriteName = payload => post('/member/checkWriteName', payload);

/**
 * @author Ash
 * @date 2024.03.31
 * @param { writeName, dmzpwd, dutyCode, medicalItemCode, treatmentCode, hospitalName }
 * @description 의사회원 고정필명, 커뮤니티 패스워드 수정
 */
const requestUpdateMemberPseudonym = payload => post('/v3/member/pseudonym', payload);

/**
 * @author Ash
 * @date 2024.03.25
 * @param { dutyCode, medicalItemCode, treatmentCode, hospitalName }
 * @description 의사회원 병원정보변경
 */
const requestUpdateMemberHospital = payload => put('/v3/member/hospital', payload);

/**
 * @author Ash
 * @date 2024.04.12
 * @description 의사회원 변경일자 갱신
 */
const requestUpdateMemberNone = () => patch('/v3/member/not-modified');

// ---------------- // 회원정보 관련 ----------------

export {
  requestCheckPassword,
  requestLogin,
  requestLogout,
  fetchMyInfo,
  requestCheckUid,
  requestCheckWriteName,
  createMember,
  createDoctor,
  fetchCodeList,
  fetchSubCodeList,
  fetchMainCodeList,
  requestMemberStatus,
  requestMemberHospitalInfo,
  fetchNiceInit,
  requestDecodeNiceData,
  requestFindIdByPhone,
  fetchMemberInfoForModify,
  updatePassword,
  fetchLeaveReason,
  requestLeaveMember,
  requestResetPassword,
  updateMemberInfo,
  updateMemberInfoV3,
  updateWriteName,
  fetchHospSearch,
  requestCheckAliasWriteName,
  requestUpdateMemberPseudonym,
  requestUpdateMemberHospital,
  requestUpdateMemberNone,
};
