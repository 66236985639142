import { post } from '..';

export const POLICY_TYPE = Object.freeze({
  PRIVACY_POLICY: '0',
});

const validatePolicyType = type => {
  if (!Object.values(POLICY_TYPE).includes(type)) {
    throw new Error(`잘못된 정책 타입입니다. ${type}(${typeof type}}`);
  }
};

// 개인정보 처리 방침 목록
export const getPolicyList = ({ type } = {}) => {
  validatePolicyType(type);
  return post('/policy/list', { policyType: type });
};

// 개인정보 처리 방침
export const getPolicy = ({ type, idx } = {}) => {
  validatePolicyType(type);
  return post('/policy/view', { policyType: type, idx });
};
