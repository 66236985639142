// 게시판 API
import { get, post } from '..';

// 홈
const fetchHomeList = payload => post('/home/main', payload);

// 홈- 파트너즈용
const fetchPartnersList = payload => post('/home/partnersList', payload);

// 홈- 지식인용
const fetchCounselList = payload => get('/api/home/knowledge-in', payload);

// 홈- 클래스
const fetchHomeClassList = payload => get('/api/home/classes', payload);

// PC 용 홈
const fecthPCHomeList = payload => post('/home/mainPC', payload);

// ---------- 커뮤니티 ---------- //
// 커뮤니티 게시글 목록
const fetchCommunityList = payload => post('/community/list', payload);

// 베스트 글
const fetchBestCommunityList = payload => post('/community/best', payload);

// 베스트 전체
const fetchAllBestCommunityList = payload => post('/community/bestAll', payload);

// 커뮤니티 게시글 상세
const fetchBoardInfo = payload => post('/community/view', payload);

// 커뮤니티 게시글 상세 ( 수정용 )
const fetchBoardInfoForModify = payload => post('/community/view2', payload);

// 커뮤니티 글 쓰기
const createCommunity = payload => post('/community/write', payload);

// 커뮤니티 글 수정
const updateCommunity = payload => post('/community/edit', payload);

// 커뮤니티 게시글 삭제
const deleteCommunity = payload => post('/community/delete', payload);

// 커뮤니티 게시글 삭제
const modifyCommunityNotice = payload => post('/community/notice/edit', payload);
// ---------- // 커뮤니티 ---------- //

// ---------- 뉴스 ---------- //
// 뉴스 목록 가져오기
const fetchNewsList = payload => post('/news/list', payload);

// 뉴스 게시글 상세
const fetchNewsInfo = payload => post('/news/view', payload);
// ---------- // 뉴스 ---------- //

// ---------- 클래스 ---------- //
// 클래스 목록 가져오기
const fetchClassList = payload => post('/docpleClass/list', payload);

// 클래스 상세 정보
const fetchClassInfo = payload => post('/docpleClass/view', payload);

// 클래스 사전신청 정보
const fetchClassApplyInfo = payload => post('/docpleClass/preRegInfo', payload);

// 클래스 사전 신청
const requestApplyClass = payload => post('/docpleClass/preReg', payload);

// ---------- // 클래스 ---------- //

// ---------- 중고장터 ----------
// 중고장터 목록
const fetchJunggoList = payload => post('/junggo/list', payload);

// 중고장터 상세
const fetchJunggoInfo = payload => post('/junggo/view', payload);

// 중고장터 글쓰기
const createJunggo = payload => post('/junggo/write', payload);

// 중고장터 글 수정
const updateJunggo = payload => post('/junggo/edit', payload);

// 중고장터 글 삭제
const deleteJunggo = payload => post('/junggo/delete', payload);

// 중고장터 판매처리
const requestSoldout = payload => post('/junggo/sold', payload);
// ---------- // 중고장터 ----------

// ---------- 임대분양 ----------
// 임대분양 목록
const fetchLeaseList = payload => post('/lease/list', payload);

// 임대분양 상세
const fetchLeaseInfo = payload => post('/lease/view', payload);

// 임대분양 글쓰기
const createLease = payload => post('/lease/write', payload);

// 임대분양 글수정
const updateLease = payload => post('/lease/edit', payload);

// 임대분양 글삭제
const deleteLease = payload => post('/lease/delete', payload);
// ---------- // 임대분양 ----------

// ---------- 초빙구직 ----------
// 초빙
// 초빙목록
const fetchJobList = payload => post('/invitation/list', payload);

// 초빙목록
const fetchJobInfo = payload => post('/invitation/view', payload);

// 초빙 수정정보
const fetchJobEditInfo = payload => post('/invitation/editPageInfo', payload);

// 초빙 작성
const createJob = payload => post('/invitation/write', payload);

// 초빙 수정
const updateJob = payload => post('/invitation/modify', payload);

// 초빙 삭제
const deleteJob = payload => post('/invitation/remove', payload);

// 구직목록
const fetchJobPersonList = payload => post('/invitation/jobSearchList', payload);

// 초빙 수정정보
const fetchJobPersonEditInfo = payload => post('/invitation/jobEditPageInfo', payload);

// 구직상세
const fetchJobPersonInfo = payload => post('/invitation/jobSearchView', payload);

// 구직 작성
const createJobPerson = payload => post('/invitation/jobWrite', payload);

// 구직 수정
const updateJobPerson = payload => post('/invitation/jobModify', payload);

// 구직 삭제
const deleteJobPerson = payload => post('/invitation/jobRemove', payload);

// 초빙구직 etc
// # TODO 아래 API 확인 필요
// 내 연봉정보 입력확인
const fetchPayInfo = payload => post('/invitation/myPayInfoCheck', payload);

// 내 연봉정보 입력
const updatePayInfo = payload => post('/invitation/myPayInfoWrite', payload);

// 온라인 지원 (이메일지원)
const requestOnlineApply = payload => post('/invitation/onlineJoin', payload);

// ---------- // 초빙구직 ----------

// ---------- 공통 (커뮤니티 제외) ---------- //
// 추천 / 반대
const requestRecommend = payload => post('/board/recommend', payload);

// 통합검색
const fetchSearchBoardByKeyword = payload => post('/unitedSearch/mobile', payload);
// ---------- // 공통 (커뮤니티 제외) ---------- //

// 게시글 아이디 리스트
const fetchInsertIdList = payload => post('/admin/board/insertIdList', payload);

// 게시글 추천/반대 아이디 리스트
const fetchRecommendList = payload => post('/admin/board/recommendList', payload);

// 게시판 댓글 아이디 리스트
const fetchCommentInsertIdList = payload => post('/admin/comment/insertIdList', payload);

export {
  fetchHomeList,
  fetchCommunityList,
  fetchBestCommunityList,
  fetchAllBestCommunityList,
  fetchBoardInfo,
  requestRecommend,
  fetchNewsList,
  fetchNewsInfo,
  fetchHomeClassList,
  fetchClassList,
  deleteCommunity,
  modifyCommunityNotice,
  fetchBoardInfoForModify,
  updateCommunity,
  fetchPartnersList,
  fetchCounselList,
  createCommunity,
  fetchClassInfo,
  requestApplyClass,
  fecthPCHomeList,
  fetchClassApplyInfo,
  fetchSearchBoardByKeyword,
  fetchRecommendList,
  fetchInsertIdList,
  fetchCommentInsertIdList,
  fetchJunggoList,
  fetchJunggoInfo,
  createJunggo,
  updateJunggo,
  deleteJunggo,
  requestSoldout,
  fetchLeaseList,
  fetchLeaseInfo,
  createLease,
  updateLease,
  deleteLease,
  fetchJobList,
  fetchJobInfo,
  fetchJobEditInfo,
  createJob,
  updateJob,
  deleteJob,
  fetchJobPersonList,
  fetchJobPersonEditInfo,
  fetchJobPersonInfo,
  createJobPerson,
  updateJobPerson,
  deleteJobPerson,
  fetchPayInfo,
  updatePayInfo,
  requestOnlineApply,
};
