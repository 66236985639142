import { get, post } from '..';

// 로그인
const requestLogin = payload => post('/auth/login', payload);

/**
 * @author Ash
 * @date 2024.04.01
 * @param { id: string, pwd: string, ispc: string, loginKeep: string }
 * @description 로그인 v3
 */
const requestLoginV3 = payload => post('/v3/auth/signin', payload);

// 로그아웃
const requestLogout = () => post('/auth/logout', {});

/**
 * @author Ash
 * @date 2024.04.01
 * @description 로그아웃 v3
 */
const requestLogoutV3 = payload => post('/v3/auth/signout', payload);

const requestAuthCommunity = payload => post('/auth/communityLogin', payload);

// 기존 닥플 login
const requestLoginForLegacy = payload => get('/auth/loginOTP.do', payload);

// 기존 닥플 login
const requestLogoutForLegacy = payload => get('/auth/logout.do', payload);

/**
 * @author Ash
 * @date 2024.02.29
 * @description 병원 어드민 로그인
 * @returns
 */
const hospitalLogin = payload => post('/v2/hospital/connect', payload);

/**
 * @author Ash
 * @date 2024.03.03
 * @description 병원 어드민 연동 체크
 * @returns
 */
const hospitalConnectCheck = payload => post('/v2/hospital/verify', payload);

/**
 * @author Ash
 * @date 2024.03.03
 * @description 병원 어드민 로그아웃
 * @returns
 */
const hospitalLogout = payload => post('/v2/hospital/disconnect', payload);

const certificateEmail = payload => post('/auth/emailAuth1', payload);

const certificateEmailCode = payload => post('/auth/emailAuth2', payload);

export {
  requestAuthCommunity,
  requestLoginForLegacy,
  requestLogin,
  requestLoginV3,
  requestLogout,
  requestLogoutV3,
  requestLogoutForLegacy,
  hospitalLogin,
  hospitalConnectCheck,
  hospitalLogout,
  certificateEmail,
  certificateEmailCode,
};
