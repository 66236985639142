import { post } from '../../api';
import VueCookies from 'vue-cookies';
import { requestMemberHospitalInfo, requestMemberStatus } from '../../api/member';

const State = {
  state: {
    isAdmin: false,
    authCommunity: false,
    loggedIn: false,
    isMobile: false,
    loading: false,
    pending: false,
    background: '#fff',
    memberStatus: null,
  },
  getters: {
    isMobile: state => state.isMobile,
    loggedIn: state => state.loggedIn,
    authCommunity: state => state.authCommunity,
    isLoading: state => state.loading,
    isPending: state => state.pending,
    background: state => state.background,
    memberStatus: state => state.memberStatus,
  },
  mutations: {
    isLoggedIn(state, payload) {
      state.loggedIn = payload;
    },
    resetState(state) {
      state.isAdmin = false;
      state.loggedIn = false;
      state.authCommunity = false;
    },
    isMobile(state, payload) {
      state.isMobile = payload;
    },
    authCommunity(state, payload) {
      state.authCommunity = payload;
    },
    isLoading(state, payload) {
      state.loading = payload;
    },
    isPending(state, payload) {
      state.pending = payload;
    },
    setBackground(state, payload) {
      state.background = payload;
    },
    setMemberStatus(state, payload) {
      state.memberStatus = payload;
    },
  },
  actions: {
    // 접속한 기기가 모바일인지 체크
    isMobile({ commit }, payload) {
      commit('isMobile', payload);
    },
    async authCommunity({ commit }, pw) {
      if (pw.length < 4) {
        alert('커뮤니티 비밀번호를 입력해주세요.');
        return false;
      }
      try {
        const response = await post('/auth/communityLogin', { pw });
        if (response.data.resultCode === '0' || response.data.resultCode === 0) {
          VueCookies.set('communityToken', response.data.result.communityToken, '8h');
          commit('authCommunity', response.data.resultCode === '0');
        } else {
          alert('커뮤니티 비밀번호를 확인해주세요.');
        }
      } catch (e) {
        throw Error(e);
      }
    },
    async getMemberStatus({ commit }) {
      try {
        const response = await requestMemberStatus();
        if (response?.data?.body) {
          commit('setMemberStatus', response?.data?.body);
          return response?.data?.body;
        }
        commit('setMemberStatus', null);
        return null;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async getMemberHospitalInfo() {
      try {
        const response = await requestMemberHospitalInfo();
        return response?.data?.body;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
  },
};
export default State;
