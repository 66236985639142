import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import state from './modules/state';
import utils from './modules/utils';
import board from './modules/board';
import init from './modules/init';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    state,
    utils,
    board,
    init,
  },
});
