import Vue from 'vue';
import router from './router';
import store from './store';
import '@/assets/scss/reset.scss';
import '@/assets/scss/common.scss';
import '@/assets/scss/mixins.scss';
import GlobalMixin from './mixins/GlobalMixins';
import Detect from '@/plugin/Detect';
import ErrorHandler from './plugin/ErrorHandler';
import VueGtag from 'vue-gtag';
import VueGtm from '@gtm-support/vue2-gtm';
import vueMoment from 'vue-moment';

import App from './App.vue';

Vue.mixin(GlobalMixin);

Vue.use(vueMoment);

// 디바이스 체크
Vue.use(Detect);
store.dispatch('isMobile', Vue.$device.isMobile);
Vue.use(ErrorHandler);

Vue.config.productionTip = false;

if (process.env.NODE_ENV === 'production') {
  Vue.use(VueGtm, {
    id: 'GTM-NG3GDC87',
    defer: false,
    compatibility: false,
    nonce: '3kd012l5hv8',
    enabled: true,
    debug: true,
    loadScript: true,
    vueRouter: router,
    trackOnNextTick: false,
  });
  Vue.use(
    VueGtag,
    {
      // GA 사업부 GA id : UA-27537265-1
      config: { id: 'G-Q1G0SRJQEX' },
      // 마케팅팀 요청시 아래로 변경
      // GA 마케팅팀 GA id UA-213374869-1
      // config: { id: 'UA-213374869-1' },
      params: {
        user_id: store.getters.gaTrackingId,
      },
    },
    router,
  );

  // 제니퍼 프론트 모니터링 상용일 때만
  (function(j, en, ni, fer) {
    j['dmndata'] = [];
    j['jenniferFront'] = function(args) {
      window.dmndata.push(args);
    };
    j['dmnaid'] = fer;
    j['dmnatime'] = new Date();
    j['dmnanocookie'] = false;
    j['dmnajennifer'] = 'JENNIFER_FRONT@INTG';
    var b = Math.floor(new Date().getTime() / 60000) * 60000;
    var a = en.createElement(ni);
    a.src = 'https://d-collect.jennifersoft.com/' + fer + '/demian.js?' + b;
    a.async = true;
    en.getElementsByTagName(ni)[0].parentNode.appendChild(a);
  })(window, document, 'script', '1ff976d7');
}

// eslint-disable-next-line no-unused-vars
Vue.config.errorHandler = (err, vm, info) => {
  // 특정 오류 메시지나 조건에 따라 새로 고침
  if (isSpecificSyntaxError(err)) {
    console.error(err); // 오류 로그
    // 페이지를 새로 고침
    setTimeout(() => {
      location.reload();
    }, 3000); // 3초 후 새로 고침
  } else {
    console.warn('오류가 발생했습니다:', err);
  }
};

// 특정 오류 메시지를 체크하는 함수
function isSpecificSyntaxError(err) {
  return err.message && err.message.includes("Unexpected token '‹'");
}

function handleError(error) {
  const errorMessage = error?.message || error?.toString() || '';

  if (/loading chunk .* failed./i.test(errorMessage) || errorMessage.includes("Unexpected token '<'")) {
    console.error('에러 발생. 페이지를 새로고침합니다:', errorMessage);
    window.location.reload();
  }
}

window.onerror = function(message, source, lineno, colno, error) {
  handleError(error);
};

export default new Vue({
  router,
  store,
  render: h => h(App),
  errorHandler: error => {
    handleError(error);
  },
}).$mount('#app');
