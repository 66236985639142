/* eslint-disable*/

'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true,
});
exports['default'] = postMessageController;
function _typeof(obj) {
  '@babel/helpers - typeof';
  return (
    (_typeof =
      'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator
        ? function(obj) {
            return typeof obj;
          }
        : function(obj) {
            return obj && 'function' == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? 'symbol' : typeof obj;
          }),
    _typeof(obj)
  );
}
function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly &&
      (symbols = symbols.filter(function(sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      })),
      keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2
      ? ownKeys(Object(source), !0).forEach(function(key) {
          _defineProperty(target, key, source[key]);
        })
      : Object.getOwnPropertyDescriptors
      ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source))
      : ownKeys(Object(source)).forEach(function(key) {
          Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
  }
  return target;
}
function _defineProperty(obj, key, value) {
  key = _toPropertyKey(key);
  if (key in obj) {
    Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });
  } else {
    obj[key] = value;
  }
  return obj;
}
function _toPropertyKey(arg) {
  var key = _toPrimitive(arg, 'string');
  return _typeof(key) === 'symbol' ? key : String(key);
}
function _toPrimitive(input, hint) {
  if (_typeof(input) !== 'object' || input === null) return input;
  var prim = input[Symbol.toPrimitive];
  if (prim !== undefined) {
    var res = prim.call(input, hint || 'default');
    if (_typeof(res) !== 'object') return res;
    throw new TypeError('@@toPrimitive must return a primitive value.');
  }
  return (hint === 'string' ? String : Number)(input);
}
/**
 * 2024.02.02 - v1.0.0 cortin
 * postMessageController 함수는 아래의 메서드들을 제공합니다.
 * @see 바벨 + js 트렌스파일 https://blueantkr.atlassian.net/wiki/spaces/Tech/pages/882049200/postMessage+util+babel+js
 */
function postMessageController() {
  /**
   * getDocument 메서드는 주어진 ID에 해당하는 HTML 요소를 반환합니다.
   *
   * @param {string} documentId - HTML 요소의 ID
   * @returns {HTMLIFrameElement | null} ID에 해당하는 HTML 요소 또는 null
   */

  var getDocument = function getDocument(documentId) {
    return document.getElementById(documentId);
  };

  /**
   * getIframe 메서드는 주어진 ID에 해당하는 iframe의 contentWindow를 반환합니다.
   *
   * @param {string} documentId - iframe의 ID
   * @returns {Window | null} ID에 해당하는 iframe의 contentWindow 또는 null
   */
  var getIframe = function getIframe(documentId) {
    var iframe = getDocument(documentId);
    if (!iframe) {
      throw new Error('Document with id '.concat(documentId, ' not found'));
    }
    return iframe.contentWindow;
  };

  /**
   * sendMessageParentToChild 메서드는 부모에서 자식으로 메시지를 보냅니다.
   *
   * @param {Object} param0 - 메시지를 보낼 정보를 담은 객체
   * @param {string} param0.documentId - 메시지를 보낼 iframe의 ID
   * @param {Record<string, unknown>} param0.message - 보낼 메시지
   * @param {string} [param0.targetOrigin] - 메시지를 보낼 대상의 origin
   */
  var sendMessageParentToChild = function sendMessageParentToChild(_ref) {
    var documentId = _ref.documentId,
      message = _ref.message,
      targetOrigin = _ref.targetOrigin;
    var iframe = getIframe(documentId);
    if (!iframe) {
      throw new Error('Document with id '.concat(documentId, " not found Can't send message"));
    }
    iframe.postMessage(
      _objectSpread(
        _objectSpread({}, message),
        {},
        {
          documentId: documentId,
        },
      ),
      targetOrigin !== null && targetOrigin !== void 0 ? targetOrigin : '*',
    );
  };

  /**
   * sendMessageChildToParent 메서드는 자식에서 부모로 메시지를 보냅니다.
   *
   * @param {Object} param0 - 메시지를 보낼 정보를 담은 객체
   * @param {string} param0.documentId - 메시지를 보낼 iframe의 ID
   * @param {Record<string, unknown>} param0.message - 보낼 메시지
   * @param {string} [param0.targetOrigin] - 메시지를 보낼 대상의 origin
   */
  var sendMessageChildToParent = function sendMessageChildToParent(_ref2) {
    var documentId = _ref2.documentId,
      message = _ref2.message,
      targetOrigin = _ref2.targetOrigin;
    if (typeof window.parent.postMessage !== 'function') {
      throw new Error('Parent window postMessage is not a function');
    }
    window.parent.postMessage(
      _objectSpread(
        _objectSpread({}, message),
        {},
        {
          documentId: documentId,
        },
      ),
      targetOrigin !== null && targetOrigin !== void 0 ? targetOrigin : '*',
    );
  };

  /**
   * addPostMessageEventListener 메서드는 'message' 이벤트 리스너를 추가합니다.
   */
  var addPostMessageEventListener = function addPostMessageEventListener(callback) {
    if (typeof callback !== 'function') {
      throw new Error('callback must be a function');
    }
    window.addEventListener('message', function(event) {
      callback(event);
    });
  };

  /**
   * removePostMessageEventListener 메서드는 'message' 이벤트 리스너를 제거합니다.
   */
  var removePostMessageEventListener = function removePostMessageEventListener(callback) {
    if (typeof callback !== 'function') {
      throw new Error('callback must be a function');
    }
    window.removeEventListener('message', function(event) {
      callback(event);
    });
  };
  return {
    getDocument: getDocument,
    getIframe: getIframe,
    sendMessageParentToChild: sendMessageParentToChild,
    addPostMessageEventListener: addPostMessageEventListener,
    removePostMessageEventListener: removePostMessageEventListener,
    sendMessageChildToParent: sendMessageChildToParent,
  };
}
