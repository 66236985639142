/**
 * Checks if the user agent belongs to the Docple native app.
 * @param {string} userAgent - The user agent string.
 * @returns {boolean} - Returns true if the user agent belongs to the Docple native app, otherwise false.
 */
export const isDocpleNative = userAgent => {
  return isDocpleNativeV1(userAgent) || isDocpleNativeV2(userAgent);
};

/**
 * Checks if the user agent belongs to the Docple native app.
 * @param {string} userAgent - The user agent string.
 * @returns {boolean} - Returns true if the user agent belongs to the Docple native app, otherwise false.
 */
export const isDocpleNativeV1 = userAgent => {
  const ua = userAgent || window.navigator.userAgent;
  return /docple/.test(ua);
};

/**
 * Checks if the user agent is from Docple Native V2.
 * @param {string} userAgent - The user agent string to be checked. If not provided, the window.navigator.userAgent will be used.
 * @returns {boolean} - Returns true if the user agent is from Docple Native V2, otherwise returns false.
 */
export const isDocpleNativeV2 = userAgent => {
  const ua = userAgent || window.navigator.userAgent;
  return /docple\/v2/.test(ua);
};

/**
 * Retrieves the version of the Docple native app from the user agent.
 * @param {string} userAgent - The user agent string.
 * @returns {string|null} - Returns the version of the Docple native app if found, otherwise null.
 */
export const getDocpleNativeVersion = userAgent => {
  if (!isDocpleNative(userAgent)) {
    return null;
  }

  const ua = userAgent || window.navigator.userAgent;
  const match = ua.match(/docple\/(?:v\d+\/)?(\d+\.\d+\.\d+)/);
  return match ? match[1] : null;
};

/**
 * Represents the argument object.
 * @typedef {Object} Argument
 * @property {string} functionName - The name of the function.
 * @property {number} [timeoutTime=30*1000] - The timeout time.
 */

/**
 * Calls a function in the Docple native app.
 * @param {Argument} argument - The argument object.
 * @returns {Promise} - Returns a promise that resolves with the response from the Docple native app.
 */
const callDocpleNative = argument => {
  return new Promise((resolve, reject) => {
    if (!isDocpleNative()) {
      reject(new Error('callDocpleNative: not Docple native app'));
    }

    // android
    if (isDocpleNativeV2()) {
      window.flutter_inappwebview.callHandler('docpleCommand', JSON.stringify(argument));
      return;
    }

    const { functionName, timeoutTime = 30 * 1000 } = argument;
    const key = `${new Date().getTime()}${(Math.random() * 10).toFixed(0)}`;

    // ios
    window.docpleCommand.postMessage(
      JSON.stringify({
        functionName,
        key,
        ...argument,
      }),
    );

    const messageHandler = event => {
      const response = event.data;
      if (response?.key !== key) return;
      resolve(response);
      window.removeEventListener('message', messageHandler);
    };
    window.addEventListener('message', messageHandler);

    setTimeout(() => {
      reject(new Error(`getAppBridgeHandler: timeout ${JSON.stringify(argument)}`));
      window.removeEventListener('message', messageHandler);
    }, timeoutTime);
  });
};

/**
 * Calls the Docple native logout function.
 */
export const callDocpleNativeLogout = () => {
  callDocpleNative({ functionName: 'logout' });
};

export const callDocpleNativeExternalBrowser = ({ url }) => {
  callDocpleNative({ functionName: 'externalBrowser', argument: { url } });
};
