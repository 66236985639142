import { getInit } from '@/api/common';

const init = {
  state: {
    uid: '',
    clientIp: '',
    requestTimestamp: '',
  },
  getters: {
    init: state => ({
      uid: state.uid,
      clientIp: state.clientIp,
      requestTimestamp: state.requestTimestamp,
    }),
  },
  mutations: {
    logout(state) {
      state.uid = '';
    },
    setInit(state) {
      if (state) {
        const { uid, ip, requestTimestamp } = state;
        state.uid = uid;
        state.ip = ip;
        state.requestTimestamp = requestTimestamp;
        // console.log(state.uid);
        // window.clientIp = ip;
      }
    },
  },
  actions: {
    async getInit({ commit }) {
      try {
        const response = await getInit();
        if (response.data) {
          commit('setInit', response.data);
          window.__GA_CLIENT_IP__ = response.data.ip;
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
};

export default init;
