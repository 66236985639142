const GlobalMixin = {
  methods: {
    isIE() {
      const agent = navigator.userAgent.toLowerCase();
      return (navigator.appName === 'Netscape' && navigator.userAgent.search('Trident') !== -1) || agent.indexOf('msie') !== -1;
    },
    gaEvent(event_label = '', event_category = '', action = 'click') {
      // 상용일 때만 실행
      if (process.env.NODE_ENV !== 'production') {
        // console.log('GA_EVENT', ...arguments);
        return false;
      }
      if (action === '' || event_category === '') {
        return false;
      }
      this.$gtag.event(action, {
        event_category,
        event_label,
      });
    },
    showAlert({ text, color, bgColor, delay = 1000 }) {
      this.$store.commit('showAlert', {
        text,
        color,
        bgColor,
        delay,
      });
    },
    // 스크롤 최상단으로 이동 (모바일 일 때, 스크롤 영역 별도 처리)
    scrollToTop() {
      setTimeout(() => {
        this.scrollToCrossBrowser({ top: 0, behavior: 'auto' });
      }, 50);
    },
    scrollToCrossBrowser({ el, th, top, behavior } = {}) {
      let to = 0;
      if (el) {
        const clientRect = el.getBoundingClientRect();
        to = window.pageYOffset + clientRect.top + (th ?? 0);
      }
      if (top !== undefined) {
        to = top + (th ?? 0);
      }

      if (this.isIE()) {
        window.scroll(0, to);
        // IE 일 경우
      } else {
        window.scrollTo({ top: to, behavior: behavior ?? 'smooth' });
      }
    },
    // 라우터 이동
    go(path, useReplace = false) {
      const router = this.$router;
      const current = router.history.current;
      if (typeof path === 'object') {
        const query = JSON.stringify(path.query);
        const currentQuery = JSON.stringify(current.query);
        const checkPath = path.path ? path.path === current.path : true;
        if (query === currentQuery && checkPath) {
          // console.log('동일한 url 입니다');
        } else {
          useReplace ? router.replace(path) : router.push(path);
        }
      } else {
        if (current.path === path) {
          // console.log('동일한 url 입니다');
        } else {
          useReplace ? router.replace(path) : router.push(path);
        }
      }
    },
    // 뒤로가기
    back() {
      this.$router.go(-1);
    },
    // 스크랩
    scrap(query = '') {
      window.open(`/scrap?${query}`, '_blank', 'width=510, height=400, top=100, left=100, scrollbar=no');
    },
  },
};

export default GlobalMixin;
