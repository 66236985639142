import { requestTerms, requestTermsCode } from '../../api/common';

const utils = {
  // state = data
  state: {
    alert: {
      text: '',
      color: '',
      bgColor: '',
      show: false,
      delay: 1000,
    },
    scroll: {
      // 스크롤 중
      ing: false,
      // 스크롤 값
      value: 0,
      // 스크롤 방향
      direction: '',
      // 스크롤 대상 element
      target: null,
      // 스크롤 위치 기억용 (모바일 무찌)
      save: 0,
    },
    muzzi: {
      bid: 0,
      length: 0,
      historyStateKey: 0,
      top: 0,
    },
    // 모바일 키보드 열림 감지용
    mobile: {
      height: 0,
      keyboard: false,
    },
    // { uid : { bid, cid : { bid }, uid } }
    block: {},
    checkTerms: [],

    // 마케팅 약관 동의 내용
    marketingTermHtml: '',

    // 이용약관 페이지
    terms: {},
  },
  // getters = computed
  getters: {
    alert: state => state.alert,
    scroll: state => state.scroll,
    onKeyboard: state => state.mobile.keyboard,
    block: state => state.block,
    isBlock: state => ({ id, type, uid, bid }) => {
      const blockByUser = state.block[uid];
      if (!blockByUser) return false;

      if (type === 'bid') {
        const blockBoardByUser = blockByUser.bid ?? [];
        return blockBoardByUser.includes(id);
      }

      if (type === 'uid') {
        const blockUidByUser = blockByUser.uid ?? [];
        return blockUidByUser.includes(id);
      }

      if (type === 'cid') {
        const blockCommentByUserInBoard = blockByUser.cid[bid] ?? [];
        return blockCommentByUserInBoard.includes(id);
      }
    },
    checkTerms: state => state.checkTerms,
    marketingTermHtml: state => state.marketingTermHtml,

    terms: state => state.terms,
  },
  // mutations, actions(api) = methods
  mutations: {
    showAlert(state, payload) {
      state.alert.text = payload.text;
      state.alert.color = payload.color;
      state.alert.bgColor = payload.bgColor;
      state.alert.delay = payload.delay === null ? 1000 : payload.delay;
      state.alert.show = true;
      setTimeout(() => {
        state.alert.show = false;
      }, state.alert.delay);
    },
    // 스크롤 대상
    setScrollTarget(state, payload) {
      state.scroll.target = payload;
    },
    // 스크롤 이벤트
    isScrolling(state, payload) {
      state.scroll.ing = true;
      state.scroll.direction = state.scroll.value > payload ? 'up' : 'down';
      state.scroll.value = payload;
      setTimeout(() => {
        state.scroll.ing = false;
        state.scroll.direction = '';
      }, 500);
    },
    // 디바이스 높이
    setMobileHeight(state) {
      state.mobile.height = window.innerHeight;
    },
    // 키보드 열림/닫힘
    isOpenKeyboard(state, payload) {
      state.mobile.keyboard = payload;
    },
    setMuzzi(state, { bid, length, historyStateKey, top } = {}) {
      const updateState = {};
      if (bid !== undefined) {
        updateState.bid = bid;
      }
      if (length !== undefined) {
        updateState.length = length;
      }
      if (historyStateKey !== undefined) {
        updateState.historyStateKey = historyStateKey;
      }
      if (top !== undefined) {
        updateState.top = top;
      }
      state.muzzi = { ...state.muzzi, ...updateState };
    },
    initMuzzi: state => {
      state.muzzi = {
        bid: 0,
        length: 0,
        historyStateKey: 0,
        top: 0,
      };
    },
    loadBlock: (state, jsonBlockString) => {
      if (!jsonBlockString) {
        state.block = {};
        return;
      }

      try {
        state.block = JSON.parse(jsonBlockString);
      } catch {
        state.block = {};
      }
    },
    addBlock: (state, { uid, type, id, bid }) => {
      if ((uid === undefined) | (id === undefined)) return;
      const blockByUser = state.block[uid] || {
        cid: {},
        bid: [],
        uid: [],
      };

      if (type === 'bid' && !blockByUser.bid.includes(id)) {
        blockByUser.bid = [...blockByUser.bid, id];
      }

      if (type === 'cid' && bid !== undefined) {
        const blockCommentByUserInBoard = blockByUser.cid[bid] || [];
        if (!blockCommentByUserInBoard.includes(id)) {
          blockByUser.cid[bid] = [...blockCommentByUserInBoard, id];
        }
      }

      if (type === 'uid' && !blockByUser.uid.includes(id)) {
        blockByUser.uid = [...blockByUser.uid, id];
      }

      state.block = {
        ...state.block,
        [uid]: blockByUser,
      };
      window.localStorage.setItem('block', JSON.stringify(state.block));
    },
    setCheckTerms(state, payload) {
      state.checkTerms = payload;
    },
    setMarketingTermText(state, payload) {
      state.marketingTermHtml = payload;
    },
    setTerms(state, payload) {
      state.terms = payload;
    },
  },
  actions: {
    async getTerms({ commit }) {
      try {
        const response = await requestTerms();
        if (response?.data?.success && Array.isArray(response?.data?.body)) {
          const checkList = response?.data?.body.map(item => ({
            termsVersion: item.termsVersion,
            termsKind: item.termsKindCode,
            agree: false,
          }));

          commit('setCheckTerms', checkList);
          return response?.data?.body || [];
        }
      } catch (error) {
        console.error(error);
      }
    },
    async actionTermContents({ commit, state }, payload) {
      if (state.terms[payload]) {
        return;
      }
      try {
        const response = await requestTermsCode(payload);
        if (response?.data?.success && Array.isArray(response?.data?.body) && response?.data?.body?.length > 0) {
          const responseTerms = response?.data?.body;

          commit('setTerms', { ...state.terms, [payload]: responseTerms });
          return;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getMarketingTerm({ state, commit }) {
      // 자주 호출방지 자체 캐싱
      if (state.marketingTermHtml) return;
      try {
        // 마케팅 코드 60000
        const response = await requestTermsCode('60000');
        if (response?.data?.success && Array.isArray(response?.data?.body) && response?.data?.body?.length > 0) {
          const lastDateTerm = response?.data?.body.pop();
          commit('setMarketingTermText', lastDateTerm.content);
          return;
        }
        commit('setMarketingTermText', '');
      } catch (error) {
        console.error('getMarketingTerm >>>>> ', error);
      }
    },
  },
};

export default utils;
