import UAParser from 'ua-parser-js';
const ua = UAParser(navigator.userAgent);

export const isMobile = ['tablet', 'mobile'].includes(ua.device.type);
export const isIos = ua.os.name == 'iOS';

export default {
  isMobile,
  isIos,
};
